import React, { FC } from 'react';

import TodoPopup from '../TodoPopup';

import { IInfo } from './models';
import './Info.scss';

const Info: FC<IInfo> = ({ postItNoteImage, postItNoteAlt, children }) => (
  <div className="dt-media-info-box__info">
    <div className="dt-media-info-box__info-holder">
      {postItNoteImage ? (
        <TodoPopup postItNoteImage={postItNoteImage} postItNoteAlt={postItNoteAlt} />
      ) : null}
      {children}
    </div>
  </div>
);

export default Info;
