import React, { FC } from 'react';
import Button from 'react-bootstrap/Button';

import MediaInfoBox from 'components/common/MediaInfoBox/MediaInfoBox';
import Media from 'components/common/MediaInfoBox/Media';
import Info from 'components/common/MediaInfoBox/Info';
import UmbracoImage from 'components/common/Image/UmbracoImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import extractUrlFromMultiPicker from 'utils/urlMultiPicker';
import IngredientItem from './IngredientItem';

import { IProductIngredients } from './models';

import './ProductIngredients.scss';

const ProductIngredients: FC<IProductIngredients> = (props) => (
  <div className="dt-ingredients">
    <MediaInfoBox isSwap sectionLandmark={props.titleRegular}>
      <Media>
        <UmbracoImage image={props.image} alt={props.imageAlt} />
      </Media>

      <Info>
        <h2 className="dt-ingredients__title">{props.titleRegular}</h2>
        <dl className="dt-ingredients__list">
          {props.group?.map((item, iter) => (
            <IngredientItem key={`ingredients-groups-${iter}`} {...item} />
          ))}
        </dl>
        {props?.description ? (
          <p className="dt-ingredients__description">{props.description}</p>
        ) : null}
        <DangerouslySetInnerHtml
          className="dt-ingredients__description"
          html={props.descriptionRte}
        />
        {props.buttonText ? (
          <div className="dt-media-info-box__info-btn">
            <Button variant="light" href={extractUrlFromMultiPicker(props.buttonLink)}>
              {props.buttonText}
            </Button>
          </div>
        ) : null}
      </Info>
    </MediaInfoBox>
  </div>
);

export default ProductIngredients;
