import React, { FC } from 'react';

import UmbracoImage from 'components/common/Image/UmbracoImage';

import { ITodoPopup } from './models';
import './TodoPopup.scss';

const TodoPopup: FC<ITodoPopup> = ({ postItNoteImage, postItNoteAlt }) => {
  return (
    <div className="dt-media-info-box__todo-popup">
      <UmbracoImage image={postItNoteImage} alt={postItNoteAlt || 'post it image'} />
    </div>
  );
};

export default TodoPopup;
